import { Component, OnInit, Input } from '@angular/core';
import { AccreditationRequestDetailsService } from 'src/app/features/cabs/modules/accreditation-requests/services/accreditation-request-details.service';
import { saveAs } from 'file-saver';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-files-viewer',
  templateUrl: './files-viewer.component.html',
})
export class FilesViewerComponent implements OnInit {
  @Input() files: any = [];
  @Input() cols = 1;
  @Input() downloadWithApi = false;
  docsEditable = false;
  requestToken: string;

  constructor(
    private service: AccreditationRequestDetailsService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.service.requestToken.subscribe((data) => (this.requestToken = data));
  }
  fileType(fileName) {
    if (fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'pdf':
          return 'pdf';
        case 'jpeg':
        case 'jpg':
          return 'jpg';
        case 'png':
          return 'png';
        case 'doc':
        case 'docx':
        case 'wpd':
        case 'tex':
          return 'doc';
        case 'xlsx':
        case 'xls':
          return 'xls';
        case 'zip':
        case 'rar':
          return 'zip';
        case 'ppt':
        case 'pptx':
          return 'pptx';
        default:
          return false;
      }
    }

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  download(filePath, fileName) {
    const model = {
      attachment_token: filePath,
      request_token: this.requestToken,
    };
    this.service.downloadFile(model).subscribe((data) => {
      const blob = new Blob([data]);
      // console.log(data.data.name);
      console.log(blob);
      saveAs(blob, `${fileName}`);
    });
  }
  onDocumentDownloader(filePath, fileName) {
    const model = {
      file_download: filePath,
    };
    this.sharedService.documentDownloader(model).subscribe((data) => {
      const blob = new Blob([data]);
      // console.log(data.data.name);
      console.log(blob);
      saveAs(blob, `${fileName}`);
    });
  }
}
