<section class="create">
  <div class="create_title">{{ data.title }}</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="create_content">
      <form (ngSubmit)="onEgacCreateSubmit()" [formGroup]="egacCreateForm">
        <div class="row">
          <div class="col-sm-6 pr-2">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>First Name</mat-label>
              <input formControlName="firstName" matInput maxlength="20" />
              <mat-error *ngIf="hasError('firstName', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('firstName').errors?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('firstName', 'pattern')">
                Accept only Characters
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('firstName').errors?.serverError">
                {{ egacCreateForm.get('firstName').errors?.serverError }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 pl-2">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Father Name</mat-label>
              <input formControlName="fatherName" matInput maxlength="20" />
              <mat-error *ngIf="hasError('fatherName', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('fatherName').errors?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('fatherName', 'pattern')">
                Accept only Characters
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 pr-2">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Grandfather Name</mat-label>
              <input formControlName="grandfatherName" matInput maxlength="20" />
              <mat-error *ngIf="hasError('grandfatherName', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('grandfatherName').errors
                    ?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('grandfatherName', 'pattern')">
                Accept only Characters
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6 pl-2">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Family Name</mat-label>
              <input formControlName="familyName" matInput maxlength="20" />
              <mat-error *ngIf="hasError('familyName', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('familyName').errors?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('familyName', 'pattern')">
                Accept only Characters
              </mat-error>
            </mat-form-field>
          </div>
          <!---->
          <div [hidden]="data.userType  !== userTypeTokens.trainee" class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Full Name [In Arabic]</mat-label>
              <!-- [required]="selectedUserType.token == userTypeTokens.trainee" -->
              <input formControlName="full_name_trainee_ar" matInput maxlength="100"
                [required]="selectedUserType.token == userTypeTokens.trainee" />
              <!-- <mat-error *ngIf="hasError('full_name_trainee_ar', 'required')">
                Field in Arabic is required
              </mat-error> -->
              <mat-error *ngIf="hasError('full_name_trainee_ar', 'pattern')">
                {{'createAccount.Accept only Characters' | translate}}
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('full_name_trainee_ar').errors?.serverError">
                {{ egacCreateForm.get('full_name_trainee_ar').errors?.serverError }}
              </mat-error>
              <mat-error *ngIf="
                egacCreateForm.get('full_name_trainee_ar').errors?.whiteSpaceValidator || egacCreateForm.get('full_name_trainee_ar').errors?.required
                ">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div [hidden]="data.userType !== userTypeTokens.trainee" class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>{{'forms.fullname' | translate }} {{'createAccount.[In English] according to training
                certificate' | translate}}</mat-label>
              <!-- [required]="selectedUserType.token == userTypeTokens.trainee" -->
              <input formControlName="full_name_trainee_en" matInput maxlength="200"
                [required]="selectedUserType.token == userTypeTokens.trainee" />
              <!-- <mat-error *ngIf="hasError('full_name_trainee_ar', 'required')">
                Field in Arabic is required
              </mat-error> -->
              <mat-error *ngIf="hasError('full_name_trainee_en', 'pattern')">
                {{'createAccount.FieldinEnglish' | translate}}
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('full_name_trainee_en').errors?.serverError">
                {{ egacCreateForm.get('full_name_trainee_en').errors?.serverError }}
              </mat-error>
              <mat-error *ngIf="
                egacCreateForm.get('full_name_trainee_en').errors?.whiteSpaceValidator  || egacCreateForm.get('full_name_trainee_en').errors?.required
                ">
                {{'userProfile.Field is Required' | translate}}
              </mat-error>
            </mat-form-field>
          </div>

          <!---->
          <div
            [hidden]="data.userType === userTypeTokens.cabUser ||  data.userType  === userTypeTokens.cabRepresentative"
            class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Position</mat-label>
              <input
                [required]="data.userType  !== userTypeTokens.cabUser &&  data.userType  !== userTypeTokens.cabRepresentative"
                formControlName="position" matInput maxlength="200" />
              <mat-error *ngIf="
                  egacCreateForm.get('position').errors?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('position', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('position', 'pattern')">
                Accept only Letters and Spaces
              </mat-error>
            </mat-form-field>
          </div>
          <div [hidden]="data.userType  !== userTypeTokens.trainee  &&  data.userType !== userTypeTokens.externalStaff"
            class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Company</mat-label>
              <input
                [required]="data.userType  === userTypeTokens.trainee  ||  data.userType === userTypeTokens.externalStaff"
                formControlName="company" matInput maxlength="200" />
              <mat-error *ngIf="egacCreateForm.get('company').errors?.whiteSpaceValidator">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('company', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('company', 'pattern')">
                Accept only Letters and Spaces
              </mat-error>
            </mat-form-field>
          </div>
          <div [hidden]="data.userType  !== userTypeTokens.trainee &&  data.userType !== userTypeTokens.externalStaff"
            class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Company Country</mat-label>
              <mat-select class="profile-inputs_c-grey-6" formControlName="company_country_token"
                [required]="data.userType === userTypeTokens.externalStaff">
                <mat-option *ngFor="let item of countryCodes" [value]="item.country_token">{{ item.countryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('company_country_token', 'required')">
                Is Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Nationality</mat-label>
              <mat-select class="profile-inputs_c-grey-6" formControlName="nationality">
                <mat-option *ngFor="let item of countryCodes" [value]="item.country_token">{{ item.countryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('nationality', 'required')">
                Is Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <div class="form-group mb-4">
              <ngx-intl-tel-input [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [maxLength]="40"
                [numberFormat]="PhoneNumberFormat.International" [phoneValidation]="true" [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name,
                SearchCountryField.DialCode
                ]" [searchCountryFlag]="true" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Bahrain"
                [separateDialCode]="separateDialCode" formControlName="phone" name="phone">
              </ngx-intl-tel-input>
              <mat-error *ngIf="
                  egacCreateForm.controls['phone'].touched &&
                  egacCreateForm.controls['phone'].invalid &&
                  !egacCreateForm.controls['phone'].pristine
                ">
                <div *ngIf="egacCreateForm.controls['phone'].errors.required" class="intl-validation">
                  Field is required
                </div>
                <div *ngIf="egacCreateForm.controls['phone'].errors" class="intl-validation">
                  Enter valid number according selected country (10 ~ 40)
                </div>
              </mat-error>
            </div>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput maxlength="200" placeholder="pat@example.com" />
              <mat-error *ngIf="hasError('email', 'required')">
                Field is Required
              </mat-error>
              <mat-error *ngIf="hasError('email', 'pattern')">Invalid Format
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('email').errors?.serverError">
                {{ egacCreateForm.get('email').errors?.serverError }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <div *ngIf="data.userType == this.userTypeTokens.cabUser" class="title">HCB</div>
          </div>
          <div [hidden]="data.userType !== this.userTypeTokens.cabUser" class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>HCB Name [In English]</mat-label>
              <input [required]="data.userType == this.userTypeTokens.cabUser" formControlName="cabNameEnglish" matInput
                maxlength="200" />
              <mat-error *ngIf="hasError('cabNameEnglish', 'required')">
                Field in English is required
              </mat-error>
              <mat-error *ngIf="hasError('cabNameEnglish', 'pattern')">
                Field in English accept only English Letters,Numbers and Spaces
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('cabNameEnglish').errors?.serverError">
                {{ egacCreateForm.get('cabNameEnglish').errors?.serverError }}
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('cabNameEnglish').errors
                    ?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
            </mat-form-field>
          </div>
          <div [hidden]="data.userType !== this.userTypeTokens.cabUser" class="col-sm-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label>HCB Name [In Arabic]</mat-label>
              <!-- [required]="data.userType == this.userTypeTokens.cabUser" -->
              <input formControlName="cabNameArabic" matInput maxlength="200" />
              <!-- <mat-error *ngIf="hasError('cabNameArabic', 'required')">
                Field in Arabic is required
              </mat-error> -->
              <mat-error *ngIf="hasError('cabNameArabic', 'pattern')">
                Field in Arabic accept only Arabic Letters,Numbers and Spaces
              </mat-error>
              <mat-error *ngIf="egacCreateForm.get('cabNameArabic').errors?.serverError">
                {{ egacCreateForm.get('cabNameArabic').errors?.serverError }}
              </mat-error>
              <mat-error *ngIf="
                  egacCreateForm.get('cabNameArabic').errors
                    ?.whiteSpaceValidator
                ">
                Field is Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <div class="btn-container">
              <button [class.loading]="loading" [disabled]="!egacCreateForm.valid && !loading" class="btn-lg w-100"
                class="btn-create w-100" color="primary" mat-raised-button>
                <span>Create Account</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</section>
