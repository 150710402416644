import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AllTrainingListService {

  trainingFormDetails = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService) {
  }

  getAllTrainingList(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_training`, model)
      .pipe(catchError(this.handleError));
  }

  getPlannedYears() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_plans`)
      .pipe(catchError(this.handleError));
  }

  getTrainingPlan(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/get_training_plans`, model)
      .pipe(catchError(this.handleError));
  }

  createTrainingCourse(model) {
    // const formData = new FormData();
    // if (imgUpload) {
    //   formData.append('course_image', imgUpload, imgUpload.name);
    // }
    // for (const prop in model) {
    //   if (model.hasOwnProperty(prop)) {
    //     formData.append(prop, model[prop]);
    //   }
    // }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_course`, model)
      .pipe(catchError(this.handleError));
  }

  editTrainingCourse(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_course`, model)
      .pipe(catchError(this.handleError));
  }

  uploadImage(fileToUpload) {
    if (fileToUpload && fileToUpload.name) {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.apiService
        .post(`${environment.apiUrl}/api/system/upload_img_course`, formData)
        .pipe(catchError(this.handleError));
    }
  }

  traineePayment(model) {
    // debugger
    const formData = new FormData();
    formData.append('course_token', model.course_token);
    formData.append('payment_option', model.payment_option);
    formData.append('account_holder_name', model.account_holder_name);
    formData.append('number_of_participants', model.number_of_participants);
    formData.append('payment_type', model.payment_type);
    if (model.attached_file) {
      model.attached_file.forEach((element) => {
        if (element.type) {
          formData.append('attached_file', element, element.name);
        }
      });
    }
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/trainee_pay_course_fees`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  traineeUpdatePayment(model) {
    // debugger
    const formData = new FormData();
    formData.append('course_token', model.course_token);
    formData.append('payment_option', model.payment_option);
    formData.append('account_holder_name', model.account_holder_name);
    formData.append('number_of_participants', model.number_of_participants);
    formData.append('payment_type', model.payment_type);
    if (model.attached_file) {
      model.attached_file.forEach((element) => {
        if (element.type) {
          formData.append('attached_file', element, element.name);
        }
      });
    }
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/trainee_update_course_payment`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  TM_FM_Payment(model) {
    // debugger
    const formData = new FormData();
    formData.append('course_token', model.course_token);
    formData.append('enrollment_token', model.enrollment_token);
    formData.append('payment_option', model.payment_option);
    formData.append('account_holder_name', model.account_holder_name);
    formData.append('number_of_participants', model.number_of_participants);
    formData.append('payment_type', model.payment_type);
    if (model.attached_file) {
      model.attached_file.forEach((element) => {
        if (element.type) {
          formData.append('attached_file', element, element.name);
        }
      });
    }
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/tm_pay_and_approve_course_payment`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  traineePaymentForFree(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/training_course_enrollment`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  trainneeApply(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/training_course_enrollment`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  allPaymentRequests(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_training_payment`, model)
      .pipe(catchError(this.handleError));
  }

  getCurrencyList(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_currency_list`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }

  getDataToPaymentGetway(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/OnlinePayment`, data)
      .pipe(catchError(this.handleError));
  }
  checkPaymentConfirmation(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/paymentConfirmation`, data)
      .pipe(catchError(this.handleError));
  }
  approveCourseEnrollment(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/tm_approve_course_enrollment`, data)
      .pipe(catchError(this.handleError));
  }
  downloadTrainingInvoiseAddressAsPdf(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/downloadTrainingInvoice`,
        model
      )
      .pipe(catchError(this.handleError));
  }
  courseAttendenssExport(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/courseAttendenssExport`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  GetPaymentOptions() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_the_payment_options`)
      .pipe(catchError(this.handleError));
  }

  GetCourseTitleFilter(model) {
    // debugger
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/get_course_title_for_filter`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  exportTrainingPaymentsExport(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/TrainingPaymentsExport`,
        model
      )
      .pipe(catchError(this.handleError));
  }

}
